<template>
  <div class="w3-container w3-light-grey w3-margin-top" id="home" style="padding:60px 16px">
  <div class="w3-row-padding">
    <div class="w3-col m6">
      <img class="w3-image w3-round-large" :src="Image" :alt="Alt" width="700" height="394">
    </div>
    <div class="w3-col m6  w3-padding w3-center">
      <h1 style="font-weight:bold; color:#585858">{{Title}}</h1><br>
      <h4>{{Text}}</h4><br>
      
      <a href="#business" class="w3-button w3-amber w3-margin w3-padding-8"><i class="fa fa-th"> </i> For Businesses</a>
      <a href="#seller" class="w3-button w3-amber w3-padding-8"><i class="fa fa-th"> </i> For Sales Agents</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
props: ['Image','Alt','Title','Text']
}
</script>

<style>

</style>