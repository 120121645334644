<template>
    <div class="tcs">
      <br>
    <h1>Terms of Use & Privacy</h1>
    <h1>Terms of Use</h1>
    <ol>
        <li>
            <h2>Introduction</h2>
            <p>Welcome to Avigail Assistant, an AI-powered Android application designed to help social media users and brands manage their online presence and social commerce activities. By using our services, you agree to these Terms of Use. If you do not agree with any part of these terms, please do not use our application.</p>
        </li>
        <li>
            <h2>Eligibility</h2>
            <p>You must be at least 13 years old to use Avigail Assistant. By using the application, you represent and warrant that you meet this age requirement.</p>
        </li>
        <li>
            <h2>User Accounts</h2>
            <p>To access certain features of Avigail Assistant, you may be required to create an account. You agree to provide accurate and complete information and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        </li>
        <li>
            <h2>License</h2>
            <p>Avigail Assistant grants you a limited, non-exclusive, non-transferable, revocable license to use the application for personal and commercial purposes. You may not use the application for any illegal or unauthorized purpose.</p>
        </li>
        <li>
            <h2>Prohibited Activities</h2>
            <p>You agree not to:</p>
            <ul>
                <li>Use the application for any unlawful purpose or in violation of any local, state, national, or international law.</li>
                <li>Interfere with or disrupt the operation of the application.</li>
                <li>Attempt to gain unauthorized access to any part of the application or its related systems or networks.</li>
                <li>Use any automated means to access the application, including robots, spiders, or scrapers.</li>
            </ul>
        </li>
        <li>
            <h2>Content Ownership</h2>
            <p>All content generated by Avigail Assistant is owned by you. However, by using the application, you grant Avigail Assistant a non-exclusive, royalty-free, worldwide license to use, store, and display your content solely to provide you with the services of the application.</p>
        </li>
        <li>
            <h2>Disclaimer of Warranties</h2>
            <p>Avigail Assistant is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the application will be uninterrupted, error-free, or free of viruses or other harmful components.</p>
        </li>
        <li>
            <h2>Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, Avigail Assistant shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of the application.</p>
        </li>
        <li>
            <h2>Changes to the Terms</h2>
            <p>We may update these Terms of Use from time to time. We will notify you of any changes by posting the new terms on our website and/or within the application. Your continued use of the application after such changes constitutes your acceptance of the new Terms of Use.</p>
        </li>
        <li>
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms of Use, please contact us at moses@avi.ke.</p>
        </li>
    </ol>

    <h1>Privacy Policy</h1>
    <ol>
        <li>
            <h2>Introduction</h2>
            <p>Avigail Assistant values your privacy. This Privacy Policy describes how we collect, use, and share your personal information when you use our AI-powered Android application.</p>
        </li>
        <li>
            <h2>Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li>Personal Information: Information that you provide directly to us, such as your name, email address, and account information.</li>
                <li>Usage Information: Information about how you use the application, including your interactions with the application and the content you generate.</li>
                <li>Device Information: Information about the device you use to access the application, including its operating system, unique device identifiers, and network information.</li>
            </ul>
        </li>
        <li>
            <h2>How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul>
                <li>Provide, maintain, and improve the application.</li>
                <li>Personalize your experience with the application.</li>
                <li>Communicate with you, including responding to your inquiries and providing customer support.</li>
                <li>Monitor and analyze usage and trends to improve our services.</li>
                <li>Protect the security and integrity of the application.</li>
            </ul>
        </li>
        <li>
            <h2>Sharing Your Information</h2>
            <p>We do not share your personal information with third parties except in the following circumstances:</p>
            <ul>
                <li>With your consent.</li>
                <li>With service providers who perform services on our behalf.</li>
                <li>To comply with legal obligations, such as responding to a subpoena or court order.</li>
                <li>To protect our rights and the safety of our users and the public.</li>
            </ul>
        </li>
        <li>
            <h2>Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
        </li>
        <li>
            <h2>Data Retention</h2>
            <p>We retain your personal information for as long as necessary to provide you with the services of the application and to comply with our legal obligations.</p>
        </li>
        <li>
            <h2>Your Rights</h2>
            <p>You have the right to access, update, and delete your personal information. You may also have the right to object to or restrict certain types of processing of your personal information. To exercise these rights, please contact us at moses@avi.ke.</p>
        </li>
        <li>
            <h2>Changes to the Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website and/or within the application. Your continued use of the application after such changes constitutes your acceptance of the new Privacy Policy.</p>
        </li>
        <li>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at moses@avi.ke</p>
        </li>
    </ol>

  </div>
  </template>

<style>
.tcs {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
    padding: 40px;
}
.top {
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
}
h2 {
    color: #2980b9;
}
ol {
    padding-left: 20px;
}
li {
    margin-bottom: 10px;
}
ul {
    list-style-type: disc;
    padding-left: 20px;
}
</style>
