<template>
  <!DOCTYPE html>
<html>
<title>Avigail - Home</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>



<!-- Header with full-height image -->
<RowNotFound :Title="TopTitle" :Image="TopImage" :Alt="TopAlt" :Text="TopText"/>


</body>
</html>

</template>

<script>
import { ref } from 'vue';
import RowNotFound from "../components/RowNotFound"


export default {
  name: 'App',
  components: {RowNotFound},
  methods: {
show: function(element) {
  document.getElementById("img01").src = element.currentTarget.src;
  document.getElementById("modal01").style.display = "block";
  var captionText = document.getElementById("caption");
  captionText.innerHTML = element.currentTarget.alt;
},
w3_close: function () {
  var mySidebar = document.getElementById("mySidebar");
    mySidebar.style.display = "none";
},
w3_open: function () {
  var mySidebar = document.getElementById("mySidebar");
  if (mySidebar.style.display === 'block') {
    mySidebar.style.display = 'none';
  } else {
    mySidebar.style.display = 'block';
  }
}
},
data(){
    return {
      storeitems: [],
      logo_link:"",
      logos: [],
      SalesAgentImage: require('@/assets/home-sales.jpg'),
      SalesAgentAlt:"Sales Agent",
      TopImage: require('@/assets/not-found.jpeg'),
      TopAlt: "Sales Image",
      logo_text:"avigail",
      imageLink: "http://localhost:1337"
    }
  },
    mounted() {
    fetch("https://api-web.avi.ke/storeitems")
      .then((res) => res.json())
      .then((data) => {
        this.storeitems = data;
      });
      fetch("https://api-web.avi.ke/logos")
      .then((res) => res.json())
      .then((data) => {
        this.logo_text = "avigail";
        this.logo_link = "http://localhost:1337"+ data[0].image[0].url
      });
  },
  setup () {
    return {
      slide: ref('style'),
      TopText: "Click on below button to go to home page",
      SalesAgentTitle: "Run your own online shop, No capital requirement!",
      SalesAgentText: "You will manage an online community remotely at your convenience. We will provide you with stock and the tools to fulfil all your orders.",
      SalesAgentTextOne: "As an authorised seller of reputable brands, you will have the opportunity to exponentially grow your shop and earn from each sale you make.",
      SalesAgentTextTwo: "You will also benefit from training, book keeping software and cutting edge delivery service",
      TopTitle: "ERROR: The page you are looking for could not be found",
      leftImageRowtext: "Click on below button to go to home page",
      BusinessTitle: "Add more digital channels to your distribution chain.",
      BusinessText: "Our platform empowers your business to deliver an omnichannel retail experience that drives sales and wider product reach.",
      BusinessTextOne: "We provide you with fully managed social media shops on Facebook, Whatsapp and Instagram. This includes products posting, customer engagement, fulfilment and delivery of sales.",
      BusinessTextTwo: "You will also get indepth market analytics for your product, product category, and competitors. our smart addresses gives the analytics insightful geographical reach of your product",
      BusinessImage: require('@/assets/home-business.png'),
      Cards: [{"id":"1",
        "title":"Social Media Shops",
"text":"Managed social media shops for your products on facebook, whatsapp and instagram",
"classes":"fa fa-whatsapp w3-margin-bottom w3-jumbo w3-center w3-text-green",
"link":"/sell"},
{"id":"2",
  "title":"Fulfilment & Delivery",
"text":"Tap into the power of avi Smart addresses with effecient fulfilment and delivery of orders",
"classes":"fa fa-map-marker w3-margin-bottom w3-jumbo w3-center w3-text-red",
"link":"/maps"},
{"id":"3",
"title":"Book Keeping",
"text":"Get clear visibility of sales and expenses for all of our distributed grassroot retailers",
"classes":"fa fa-book w3-margin-bottom w3-jumbo w3-center w3-text-grey",
"link":"/books"},
{"id":"4",
  "title":"Analytics & Insights",
"text":"Avigail market intelligence gives you powerful insights into the market to support you sales",
"classes":"fa fa-bar-chart w3-margin-bottom w3-jumbo w3-center w3-text-blue",
"link":"/insights"}]
      
    }
  
}
}


</script>

<style>
body,h1,h2,h3,h4,h5,h6 {font-family: "Raleway", sans-serif}

body, html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("https://www.w3schools.com/w3images/mac.jpg");
  min-height: 100%;
}

.bold{
  font-weight: bold;
}

.w3-bar .w3-button {
  padding: 16px;
}
</style>
