<template>
  <!DOCTYPE html>
<html>
<title>Avigail - Home</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>



<!-- Header with full-height image -->
<RowImageRight :Title="TopTitle" :Image="TopImage" :Alt="TopAlt" :Text="TopText"/>


<!-- About Section -->
<TwoCardRow :Cards="Cards" :CardRowText="CardRowText" />







<!-- Contact Section -->
<div class="w3-container w3-light-grey" style="padding:128px 16px" id="contact">
  <h3 class="w3-center">CONTACT</h3>
  <p class="w3-center w3-large">Lets get in touch. Send us a message:</p>
  <div class="w3-center" style="margin-top:48px">
    <p><i class="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right"></i> Brookside Drive, Westlands, Nairobi</p>
    <p><i class="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i> Phone: +254 711999906</p>
    <p><i class="fa fa-envelope fa-fw w3-xxlarge w3-margin-right"> </i> Email: info@avi.ke</p>
    <br>
    <form action="/action_page.php" target="_blank">
      <p><input class="w3-input w3-border" type="text" placeholder="Name" required name="Name"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Email" required name="Email"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Subject" required name="Subject"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Message" required name="Message"></p>
      <p>
        <button class="w3-button w3-black" type="submit">
          <i class="fa fa-paper-plane"></i> SEND MESSAGE
        </button>
      </p>
    </form>
    <!-- Image of location/map -->
    <img src="https://www.w3schools.com/w3images/map.jpg" class="w3-image w3-greyscale" style="width:100%;margin-top:48px">
  </div>
</div>

</body>
</html>

</template>

<script>
import { ref } from 'vue';
import RowImageRight from "../components/RowImageRight"
import TwoCardRow from '../components/TwoCardRow';

export default {
  name: 'App',
  components: {RowImageRight , TwoCardRow},
  
  methods: {
show: function(element) {
  document.getElementById("img01").src = element.currentTarget.src;
  document.getElementById("modal01").style.display = "block";
  var captionText = document.getElementById("caption");
  captionText.innerHTML = element.currentTarget.alt;
},
w3_close: function () {
  var mySidebar = document.getElementById("mySidebar");
    mySidebar.style.display = "none";
},
w3_open: function () {
  var mySidebar = document.getElementById("mySidebar");
  if (mySidebar.style.display === 'block') {
    mySidebar.style.display = 'none';
  } else {
    mySidebar.style.display = 'block';
  }
}
},
data(){
    return {
      storeitems: [],
      logo_link:"",
      logos: [],
      SalesAgentImage: "https://api-web.avi.ke/uploads/pexels_kindel_media_7007177_8e2a177bdb.jpg",
      SalesAgentAlt:"Sales Agent",
      TopImage: require('@/assets/books-main.jpg'),
      TopAlt: "Sales Image",
      logo_text:"",
      imageLink: "http://localhost:1337"
    }
  },
    mounted() {
    fetch("https://api-web.avi.ke/storeitems")
      .then((res) => res.json())
      .then((data) => {
        this.storeitems = data;
      });
      fetch("https://api-web.avi.ke/logos")
      .then((res) => res.json())
      .then((data) => {
        this.logo_text = data[0].text;
        this.logo_link = "http://localhost:1337"+ data[0].image[0].url
      });
  },
  setup () {
    return {
      slide: ref('style'),
      CardRowText:"Powerful accounting tools for small and growing businesses",
      TopText: "Access and manage your books from your computer, laptop, tablet, or smartphone. Create users and assign roles so that your team can login and work online.",
      SalesAgentTitle: "Run your own online shop, No capital requirement!",
      SalesAgentText: "You will manage an online community remotely at your convenience. We will provide you with stock and the tools to fulfil all your orders.",
      SalesAgentTextOne: "As an authorised seller of reputable brands, you will have the opportunity to exponentially grow your shop and earn from each sale you make.",
      SalesAgentTextTwo: "You will also benefit from training, book keeping software and cutting edge delivery service",
      TopTitle: "Track sales, payments, and expenses, customise invoices, and run reports.",
      leftImageRowtext: "Leverage the power of grass root sales agents to increase your distribution reach",
      BusinessTitle: "Augment all your social media and chat channels with carts to complete the sale and schedule delivery",
      BusinessText: "Our platform empowers your business to deliver an omnichannel retail experience that drives sales and wider product reach.",
      BusinessTextOne: "We provide you with fully managed social media shops on Facebook, Whatsapp and Instagram. This includes products posting, customer engagement, fulfilment and delivery of sales.",
      BusinessTextTwo: "You will also get indepth market analytics for your product, product category, and competitors. our smart addresses gives the analytics insightful geographical reach of your product",
      BusinessImage: "https://api-web.avi.ke/uploads/social_selling_2fb8abf46b.png",
      Cards: [{"id":"1",
"title":"Sales",
"text":"Greatly reduce workload by auto posting all sales from your social media shops and automatically reduce your inventory.",
"classes":"fa fa-book w3-margin-bottom w3-jumbo w3-center w3-text-grey",
"image":require('@/assets/books-crosspost.jpeg'),
"link":"/sell#"},
{"id":"3",
"title":"Expenses",
"text":"Categorize and track your direct and indirect expenses and calculate your profitability for your social media shop",
"classes":"fa fa-whatsapp w3-margin-bottom w3-jumbo w3-center w3-text-green",
"image":require('@/assets/books-expense.jpeg'),
"link":"/sell"},
{"id":"2",
  "title":"Payments",
"text":"Auto post payments from mpesa and card as well as update cash payments and get account reconciliations",
"classes":"fa fa-map-marker w3-margin-bottom w3-jumbo w3-center w3-text-red",
"image":require('@/assets/books-payments.jpeg'),
"link":"/sell"},
{"id":"4",
  "title":"Reports",
"text":"Generate your income statements, account reconciliations and get insights about youe social media shop",
"classes":"fa fa-bar-chart w3-margin-bottom w3-jumbo w3-center w3-text-blue",
"image":require('@/assets/books-reports.jpg'),
"link":"/sell"}]
      
    }
  
}
}


</script>

<style>
body,h1,h2,h3,h4,h5,h6 {font-family: "Raleway", sans-serif}

body, html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("https://www.w3schools.com/w3images/mac.jpg");
  min-height: 100%;
}

.w3-bar .w3-button {
  padding: 16px;
}
</style>
