<template>
    <div class="w3-container" style="padding:10px 10px; background-color:#fff;">
        <h3 class="w3-center w3-padding-24 bold">{{CardRowText}}</h3>
        <div >
            <div v-for="card in Cards" :key="card.id" class="w3-quarter" style="padding-bottom:36px;" >
                <div class="w3-container  w3-round"><br>
        <img :src="card.image" style="width:90%;" class="w3-margin-bottom">
        <h4 class="bold">{{card.title}}</h4>
        <p style="font-weight:50; color:#2F2F2F">{{card.text}}</p>
        
      </div> 
            
            </div>
        </div>
    </div>
</template>

<script>
export default {
props: ['Cards','CardRowText']
}
</script>

<style>


</style>