<template>
  <div class="w3-container w3-white w3-padding-64" id="seller">
  <div class="w3-row-padding">
    <div class="w3-col l6 w3-padding-large">
      <img :src="Image" class="w3-round w3-image" alt="Menu" style="width:100%">
    </div>
    <div class="w3-col m6">
      <h2>{{Title}}</h2>
      <p>{{Text}}</p>
      <p>{{TextOne}}</p>
      <p>{{TextTwo}}</p>
      <p><a href="#work" class="w3-button w3-amber"><i class="fa fa-th"> </i> Start Now</a></p>

    </div>
    
  </div>
</div>
</template>

<script>
export default {
props: ['Image','Alt','Title','Text','TextOne','TextTwo','TextThree']
}
</script>

<style>

</style>