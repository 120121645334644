<template>
	<!DOCTYPE html>
	<html>
	<title>Avigail - Home</title>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
	<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway">
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

	<body>
		<!-- Header with full-height image -->
		<RowImageLeft :Title="TopTitle" :Image="TopImage" :Alt="TopAlt" :Text="TopText" />
		<!-- About Section -->
		<OneRowCards :Cards="Cards" />
		<RowImageRightText :Title="BusinessTitle" :Text="BusinessText" :TextOne="BusinessTextOne"
			:TextTwo="BusinessTextTwo" :Image="BusinessImage" :Alt="BusinessAlt" />
		<RowImageLeftText :Title="SalesAgentTitle" :Text="SalesAgentText" :TextOne="SalesAgentTextOne"
			:TextTwo="SalesAgentTextTwo" :Image="SalesAgentImage" :Alt="SalesAgentAlt" />
		<!-- Team Section -->
		<div class="w3-container w3-light-grey" style="padding:50px 16px" id="team">
			<h3 class="w3-center">THE TEAM</h3>
			<p class="w3-center w3-large">The ones who runs this company</p>
			<div v-if="storeitems.length" class="w3-row-padding" style="margin-top:64px">
				<div v-bind:key="storeitem.index" v-for="storeitem in storeitems" class="w3-col l3 m6 w3-margin-bottom">
					<div class="w3-card">
						<img :src="imageLink + storeitem.Image[0].url" alt="..." width="280" height="300">
						<div class="w3-container">
							<h3>{{ storeitem.Title }}</h3>
							<p class="w3-opacity">{{ storeitem.Price }}</p>
							<p>{{ storeitem.Description }}</p>
							<p><button class="w3-button w3-light-grey w3-block"><i class="fa fa-envelope"></i>
									Contact</button></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Promo Section "Statistics" -->
		<div class="w3-container w3-row w3-center w3-dark-grey w3-padding-64">
			<div class="w3-quarter">
				<span class="w3-xxlarge">50+</span>
				<br>Brands
			</div>
			<div class="w3-quarter">
				<span class="w3-xxlarge">8000+</span>
				<br>Social Media Shops
			</div>
			<div class="w3-quarter">
				<span class="w3-xxlarge">150000+</span>
				<br>Fulfilled Orders
			</div>
			<div class="w3-quarter">
				<span class="w3-xxlarge">5+</span>
				<br>Countries
			</div>
		</div>
		<!-- Contact Section -->
		<div class="w3-container w3-light-grey" style="padding:128px 16px" id="contact">
			<h3 class="w3-center">CONTACT</h3>
			<p class="w3-center w3-large">Lets get in touch. Send us a message:</p>
			<div class="w3-center" style="margin-top:48px">
				<p><i class="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right"></i> Brookside Drive, Westlands, Nairobi
				</p>
				<p><i class="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i> Phone: +254 711999906</p>
				<p><i class="fa fa-envelope fa-fw w3-xxlarge w3-margin-right"> </i> Email: info@avi.ke</p>
				<br>
				<form action="/action_page.php" target="_blank">
					<p><input class="w3-input w3-border" type="text" placeholder="Name" required name="Name"></p>
					<p><input class="w3-input w3-border" type="text" placeholder="Email" required name="Email"></p>
					<p><input class="w3-input w3-border" type="text" placeholder="Subject" required name="Subject"></p>
					<p><input class="w3-input w3-border" type="text" placeholder="Message" required name="Message"></p>
					<p>
						<button class="w3-button w3-black" type="submit">
							<i class="fa fa-paper-plane"></i> SEND MESSAGE
						</button>
					</p>
				</form>
				<!-- Image of location/map -->
				<img src="https://www.w3schools.com/w3images/map.jpg" class="w3-image w3-greyscale"
					style="width:100%;margin-top:48px">
			</div>
		</div>
	</body>

	</html>
</template>
<script>
import { ref } from 'vue';
import RowImageLeft from "../components/RowImageLeft"
import RowImageLeftText from '../components/RowImageLeftText';
import RowImageRightText from '../components/RowImageRightText';
import OneRowCards from '../components/OneRowCards';

export default {
	name: 'App',
	components: { RowImageLeft, RowImageLeftText, RowImageRightText, OneRowCards },
	methods: {
		show: function (element) {
			document.getElementById("img01").src = element.currentTarget.src;
			document.getElementById("modal01").style.display = "block";
			var captionText = document.getElementById("caption");
			captionText.innerHTML = element.currentTarget.alt;
		},
		w3_close: function () {
			var mySidebar = document.getElementById("mySidebar");
			mySidebar.style.display = "none";
		},
		w3_open: function () {
			var mySidebar = document.getElementById("mySidebar");
			if (mySidebar.style.display === 'block') {
				mySidebar.style.display = 'none';
			} else {
				mySidebar.style.display = 'block';
			}
		}
	},
	data() {
		return {
			storeitems: [],
			logo_link: "",
			logos: [],
			SalesAgentImage: require('@/assets/home-sales.jpg'),
			SalesAgentAlt: "Sales Agent",
			TopImage: require('@/assets/home-main.jpg'),
			TopAlt: "Sales Image",
			logo_text: "avigail",
			imageLink: "http://localhost:1337"
		}
	},
	mounted() {
		fetch("https://api-web.avi.ke/storeitems")
			.then((res) => res.json())
			.then((data) => {
				this.storeitems = data;
			});
		fetch("https://api-web.avi.ke/logos")
			.then((res) => res.json())
			.then((data) => {
				this.logo_text = "avigail";
				this.logo_link = "http://localhost:1337" + data[0].image[0].url
			});
	},
	setup() {
		return {
			slide: ref('style'),
			TopText: "Leverage the power of grass root sales agents to increase your distribution reach",
			SalesAgentTitle: "Run your own online shop, No capital requirement!",
			SalesAgentText: "You will manage an online community remotely at your convenience. We will provide you with stock and the tools to fulfil all your orders.",
			SalesAgentTextOne: "As an authorised seller of reputable brands, you will have the opportunity to exponentially grow your shop and earn from each sale you make.",
			SalesAgentTextTwo: "You will also benefit from training, book keeping software and cutting edge delivery service",
			TopTitle: "Power your sales with conversational and social commerce",
			leftImageRowtext: "Leverage the power of grass root sales agents to increase your distribution reach",
			BusinessTitle: "Add more digital channels to your distribution chain.",
			BusinessText: "Our platform empowers your business to deliver an omnichannel retail experience that drives sales and wider product reach.",
			BusinessTextOne: "We provide you with fully managed social media shops on Facebook, Whatsapp and Instagram. This includes products posting, customer engagement, fulfilment and delivery of sales.",
			BusinessTextTwo: "You will also get indepth market analytics for your product, product category, and competitors. our smart addresses gives the analytics insightful geographical reach of your product",
			BusinessImage: require('@/assets/home-business.png'),
			Cards: [{
				"id": "1",
				"title": "Social Media Shops",
				"text": "Managed social media shops for your products on facebook, whatsapp and instagram",
				"classes": "fa fa-whatsapp w3-margin-bottom w3-jumbo w3-center w3-text-green",
				"link": "/sell"
			},
			{
				"id": "2",
				"title": "Fulfilment & Delivery",
				"text": "Tap into the power of avi Smart addresses with effecient fulfilment and delivery of orders",
				"classes": "fa fa-map-marker w3-margin-bottom w3-jumbo w3-center w3-text-red",
				"link": "/maps"
			},
			{
				"id": "3",
				"title": "Book Keeping",
				"text": "Get clear visibility of sales and expenses for all of our distributed grassroot retailers",
				"classes": "fa fa-book w3-margin-bottom w3-jumbo w3-center w3-text-grey",
				"link": "/books"
			},
			{
				"id": "4",
				"title": "Analytics & Insights",
				"text": "Avigail market intelligence gives you powerful insights into the market to support you sales",
				"classes": "fa fa-bar-chart w3-margin-bottom w3-jumbo w3-center w3-text-blue",
				"link": "/insights"
			}]

		}

	}
}


</script>
<style>
body,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif
}

body,
html {
	height: 100%;
	line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
	background-position: center;
	background-size: cover;
	background-image: url("https://www.w3schools.com/w3images/mac.jpg");
	min-height: 100%;
}

.bold {
	font-weight: bold;
}

.w3-bar .w3-button {
	padding: 16px;
}
</style>