<template>
    <div class="w3-container" style="padding:30px 16px">
        <h3 class="w3-center bold">Learn what avigail products can do for you.</h3>
        <div class="w3-row-padding w3-center" style="margin-top:34px;">
            <div v-for="card in Cards" :key="card.id" class="w3-quarter " style="padding:10px" >
            <div class=" w3-round-large" style="padding:20px;">
                <i :class="card.classes" aria-hidden="true"></i>
                <p class="w3-large bold">{{card.title}}</p>
                <p>{{card.text}}</p>
                
                    <div  class="w3-button w3-amber w3-round-xlarge w3-tiny">
                    <i class="fa fa-th"></i> <router-link :to="card.link">Learn More</router-link> </div>
            </div> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
props: ['Cards','CardRowText']
}
</script>

<style>

</style>