<template>
  <!DOCTYPE html>
<html>
<title>Avigail - Home</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>



<!-- Header with full-height image -->
<RowImageRight :Title="TopTitle" :Image="TopImage" :Alt="TopAlt" :Text="TopText"/>


<!-- About Section -->
<TwoCardRow :Cards="Cards" :CardRowText="CardRowText"/>



<!-- Team Section -->
<div class="w3-container w3-light-grey" style="padding:50px 16px" id="team">
  <h3 class="w3-center">THE TEAM</h3>
  <p class="w3-center w3-large">The ones who runs this company</p>
  
  <div v-if="storeitems.length" class="w3-row-padding" style="margin-top:64px">
    <div v-bind:key="storeitem.index" v-for="storeitem in storeitems" class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-card">
        <img 
      :src="imageLink + storeitem.Image[0].url" alt="..."
          width="280" height="300"
      >
        <div class="w3-container">
          <h3>{{storeitem.Title}}</h3>
          <p class="w3-opacity">{{storeitem.Price}}</p>
          <p>{{storeitem.Description}}</p>
          <p><button class="w3-button w3-light-grey w3-block"><i class="fa fa-envelope"></i> Contact</button></p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Promo Section "Statistics" -->
<div class="w3-container w3-row w3-center w3-dark-grey w3-padding-64">
  <div class="w3-quarter">
    <span class="w3-xxlarge">50+</span>
    <br>Brands
  </div>
  <div class="w3-quarter">
    <span class="w3-xxlarge">8000+</span>
    <br>Social Media Shops
  </div>
  <div class="w3-quarter">
    <span class="w3-xxlarge">150000+</span>
    <br>Fulfilled Orders
  </div>
  <div class="w3-quarter">
    <span class="w3-xxlarge">5+</span>
    <br>Countries
  </div>
</div>




<!-- Contact Section -->
<div class="w3-container w3-light-grey" style="padding:128px 16px" id="contact">
  <h3 class="w3-center">CONTACT</h3>
  <p class="w3-center w3-large">Lets get in touch. Send us a message:</p>
  <div class="w3-center" style="margin-top:48px">
    <p><i class="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right"></i> Brookside Drive, Westlands, Nairobi</p>
    <p><i class="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i> Phone: +254 711999906</p>
    <p><i class="fa fa-envelope fa-fw w3-xxlarge w3-margin-right"> </i> Email: info@avi.ke</p>
    <br>
    <form action="/action_page.php" target="_blank">
      <p><input class="w3-input w3-border" type="text" placeholder="Name" required name="Name"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Email" required name="Email"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Subject" required name="Subject"></p>
      <p><input class="w3-input w3-border" type="text" placeholder="Message" required name="Message"></p>
      <p>
        <button class="w3-button w3-black" type="submit">
          <i class="fa fa-paper-plane"></i> SEND MESSAGE
        </button>
      </p>
    </form>
    <!-- Image of location/map -->
    <img src="https://www.w3schools.com/w3images/map.jpg" class="w3-image w3-greyscale" style="width:100%;margin-top:48px">
  </div>
</div>

</body>
</html>

</template>

<script>
import { ref } from 'vue';
import RowImageRight from "../components/RowImageRight"
import TwoCardRow from '../components/TwoCardRow';

export default {
  name: 'App',
  components: {RowImageRight, TwoCardRow},
  
  methods: {
show: function(element) {
  document.getElementById("img01").src = element.currentTarget.src;
  document.getElementById("modal01").style.display = "block";
  var captionText = document.getElementById("caption");
  captionText.innerHTML = element.currentTarget.alt;
},
w3_close: function () {
  var mySidebar = document.getElementById("mySidebar");
    mySidebar.style.display = "none";
},
w3_open: function () {
  var mySidebar = document.getElementById("mySidebar");
  if (mySidebar.style.display === 'block') {
    mySidebar.style.display = 'none';
  } else {
    mySidebar.style.display = 'block';
  }
}
},
data(){
    return {
      storeitems: [],
      logo_link:"",
      logos: [],
      SalesAgentImage: "https://api-web.avi.ke/uploads/pexels_kindel_media_7007177_8e2a177bdb.jpg",
      SalesAgentAlt:"Sales Agent",
      TopImage: require('@/assets/analytics-main.jpg'),
      TopAlt: "Sales Image",
      logo_text:"",
      imageLink: "http://localhost:1337"
    }
  },
    mounted() {
    fetch("https://api-web.avi.ke/storeitems")
      .then((res) => res.json())
      .then((data) => {
        this.storeitems = data;
      });
      fetch("https://api-web.avi.ke/logos")
      .then((res) => res.json())
      .then((data) => {
        this.logo_text = data[0].text;
        this.logo_link = "http://localhost:1337"+ data[0].image[0].url
      });
  },
  setup () {
    return {
      slide: ref('style'),
      "CardRowText":"Focus on the right key performance indicators and measure success",
      TopText: "Understand your market share, brand visibility and category performance in various sales channels and geographic regions. Learn what drives uptake of your products",
      SalesAgentTitle: "Run your own online shop, No capital requirement!",
      SalesAgentText: "You will manage an online community remotely at your convenience. We will provide you with stock and the tools to fulfil all your orders.",
      SalesAgentTextOne: "As an authorised seller of reputable brands, you will have the opportunity to exponentially grow your shop and earn from each sale you make.",
      SalesAgentTextTwo: "You will also benefit from training, book keeping software and cutting edge delivery service",
      TopTitle: "Discover insights and make confident decisions using avigail market analytics",
      leftImageRowtext: "Leverage the power of grass root sales agents to increase your distribution reach",
      BusinessTitle: "Augment all your social media and chat channels with carts to complete the sale and schedule delivery",
      BusinessText: "Our platform empowers your business to deliver an omnichannel retail experience that drives sales and wider product reach.",
      BusinessTextOne: "We provide you with fully managed social media shops on Facebook, Whatsapp and Instagram. This includes products posting, customer engagement, fulfilment and delivery of sales.",
      BusinessTextTwo: "You will also get indepth market analytics for your product, product category, and competitors. our smart addresses gives the analytics insightful geographical reach of your product",
      BusinessImage: "https://api-web.avi.ke/uploads/social_selling_2fb8abf46b.png",
      Cards: [{"id":"1",
"title":"Distribution percentages",
"text":"Get insight into the state of your product distribution and positioning compared to competition on various channels",
"classes":"fa fa-book w3-margin-bottom w3-jumbo w3-center w3-text-grey",
"image":require('@/assets/analytics-distribution.jpeg'),
"link":"/sell#"},
{"id":"3",
"title":"Market share",
"text":"Discover the overall success of your brand and how our location intelligence can help you boost your share of the pie",
"classes":"fa fa-whatsapp w3-margin-bottom w3-jumbo w3-center w3-text-green",
"image":require('@/assets/analytics-market.jpeg'),
"link":"/sell"},
{"id":"2",
  "title":"Consumption indicators",
"text":" Measure the effectiveness of your advertising campaigns and understand the full market potential for your products",
"classes":"fa fa-map-marker w3-margin-bottom w3-jumbo w3-center w3-text-red",
"image":require('@/assets/analytics-consumption.jpeg'),
"link":"/sell"},
{"id":"4",
  "title":"Customer Sentiment",
"text":"Get real time analysis of customer sentiment as they interact with your products to improve satisfaction & loyalty.",
"classes":"fa fa-bar-chart w3-margin-bottom w3-jumbo w3-center w3-text-blue",
"image":require('@/assets/analytics-sentiment.jpg'),
"link":"/sell"}]
      
    }
  
}
}


</script>

<style>
body,h1,h2,h3,h4,h5,h6 {font-family: "Raleway", sans-serif}

body, html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("https://www.w3schools.com/w3images/mac.jpg");
  min-height: 100%;
}

.w3-bar .w3-button {
  padding: 16px;
}
</style>
