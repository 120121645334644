<template>
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">


<!-- Navbar (sit on top) -->
<div class="w3-top">
  <div  class="w3-bar w3-white w3-border" style="padding:0px 16px" id="myNavbar">
    <div class="w3-bar-item w3-large" style="padding-right:0px"><router-link  to="/"><img 
      src= "./assets/green-logo.jpeg" alt="..."
          width="33" height="33"
      > </router-link> </div> 
      <div class="w3-bar-item" style="padding-top:12px"><router-link  to="/"><img 
      src="./assets/black-logo.png"
      width="105" height="30"></router-link></div>
    <!-- Right-sided navbar links -->
    <div id="nav" class="w3-right w3-hide-small">
      <router-link class="w3-bar-item nav-button" style="padding-top:15px" to="/">Home</router-link>
      <router-link class="w3-bar-item nav-button" style="padding-top:15px" to="/sell">Sell</router-link>
      <router-link class="w3-bar-item nav-button" style="padding-top:15px" to="/maps">Logistics</router-link>
      <router-link class="w3-bar-item nav-button" style="padding-top:15px" to="/books">Books</router-link>
      <router-link class="w3-bar-item nav-button" style="padding-top:15px" to="/insights">Insights</router-link>
    </div>
    <!-- Hide right-floated links on small screens and replace them with a menu icon -->

    <a href="javascript:void(0)" class="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" v-on:click="w3_open">
      <i class="fa fa-bars w3-xxlarge"></i>
    </a>
  </div>
</div>

<!-- Sidebar on small screens when clicking the menu icon -->
<nav class="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large" style="display:none" id="mySidebar">
  <a href="javascript:void(0)" v-on:click="w3_close"  class="w3-bar-item w3-button w3-large w3-padding-16">Close ×</a>
  <div v-on:click="w3_close" class="w3-bar-item w3-button" style="padding-top:15px"><router-link  to="/">Home</router-link></div>
  <div v-on:click="w3_close" class="w3-bar-item w3-button" style="padding-top:15px"><router-link to="/sell">Sell</router-link></div>
  <div v-on:click="w3_close" class="w3-bar-item w3-button" style="padding-top:15px"><router-link to="/maps">Logistics</router-link></div>
 <div v-on:click="w3_close" class="w3-bar-item w3-button" style="padding-top:15px"><router-link to="/books">Books</router-link></div>
  <div v-on:click="w3_close" class="w3-bar-item w3-button" style="padding-top:15px"><router-link to="/insights">Insights</router-link></div>
</nav>

<router-view/>

<!-- Footer -->
<footer class="w3-center w3-black w3-padding-64">
  <a href="#home" class="w3-button w3-light-grey"><i class="fa fa-arrow-up w3-margin-right"></i>To the top</a>
  <div class="w3-xlarge w3-section">
    <i class="fa fa-facebook-official w3-hover-opacity"></i>
    <i class="fa fa-instagram w3-hover-opacity"></i>
    <i class="fa fa-snapchat w3-hover-opacity"></i>
    <i class="fa fa-pinterest-p w3-hover-opacity"></i>
    <i class="fa fa-twitter w3-hover-opacity"></i>
    <i class="fa fa-linkedin w3-hover-opacity"></i>
  </div>
  <p>Powered by <a href="https://www.avi.ke" title="Avigail" target="_blank" class="w3-hover-text-green">Avigail Systems</a></p>
</footer>
</template>


<script>
import { ref } from 'vue';

export default {
  name: 'App',
  components: {
  },
  
  methods: {
show: function(element) {
  document.getElementById("img01").src = element.currentTarget.src;
  document.getElementById("modal01").style.display = "block";
  var captionText = document.getElementById("caption");
  captionText.innerHTML = element.currentTarget.alt;
},
w3_close: function () {
  var mySidebar = document.getElementById("mySidebar");
    mySidebar.style.display = "none";
},
w3_open: function () {
  var mySidebar = document.getElementById("mySidebar");
  if (mySidebar.style.display === 'block') {
    mySidebar.style.display = 'none';
  } else {
    mySidebar.style.display = 'block';
  }
}
},
data(){
    return {
      storeitems: [],
      logo_link:"",
      logos: [],
      logo_text:"",
      imageLink: "http://localhost:1337"
    }
  },
    mounted() {
    fetch("https://api-web.avi.ke/storeitems")
      .then((res) => res.json())
      .then((data) => {
        this.storeitems = data;
      });
      fetch("https://api-web.avi.ke/logos")
      .then((res) => res.json())
      .then((data) => {
        this.logo_text = data[0].text;
        this.logo_link = "http://localhost:1337"+ data[0].image[0].url
      });
  },
  setup () {
    return {
      slide: ref('style'),
      title: "Power your sales with conversational and social commerce",
      text: "Leverage the power of grass root sales agents to increase your distribution reach",
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      ipsum_title: "Lorem Ipsum",
      ipsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."

    }
  
}
}


</script>
<style>
body,h1,h2,h3,h4,h5,h6 {font-family: "Raleway", sans-serif}

body, html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("https://www.w3schools.com/w3images/mac.jpg");
  min-height: 100%;
}

.w3-bar .w3-button {
  padding: 16px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  font-size: 18px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: None;
}

#nav a:hover {
  color: #76DC14;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav a.router-link-exact-active:hover {
  color: #42b983;
}
</style>
