import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Maps from '../views/Maps.vue'
import Sell from '../views/Sell.vue'
import Books from '../views/Books.vue'
import Insights from '../views/Insights.vue'
import TermsUse from '../views/TermsUse.vue'
import notFound from '../views/notFound.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/maps',
    name: 'Map',
    component: Maps
  },
  {
    path: '/sell',
    name: 'Sell',
    component: Sell
  },
  {
    path: '/books',
    name: 'Books',
    component: Books
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsUse
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: notFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.documentElement.scrollTop = 0;
  }
})


export default router
